import * as React from 'react'
import Layout from '../components/Layout'
import BlogList, {INodePost} from '../components/BlogList'
import { graphql } from 'gatsby'
const CategoriasPosts = (props: ICategoriasPostsProps) => {
  const { tag } = props.pageContext
  console.log(props.data)
  console.log(props.pageContext)
  return (
    <Layout pageTitle="Super Cool Blog Posts">
      <BlogList title={'Categoria: '+tag} data={props.data}/>
    </Layout>
  )
}
export default CategoriasPosts

export const query = graphql`
  query ($tag: String) {
    allMdx(
      sort: {order: DESC, fields: frontmatter___date}
      filter: {frontmatter: {tags: {eq: $tag}}}
      ) {
      nodes {
        frontmatter {
          date(formatString: "dddd, DD/MM/yyyy", locale: "Es-Ni")
          title
          hero_image_alt
          hero_image {
            childrenImageSharp {
              gatsbyImageData
            }
          }
          tags
        }
        id
        excerpt
        slug
      }
    }
  }
`

interface ICategoriasPostsProps{
  data: INodePost,
  pageContext: any
}